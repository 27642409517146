<script setup lang="ts">
    const { iso2Lang, localeSuffix } = useLocales();

    const allowedCountries = {
        de: 'X5379A5F5DDD2F96309E8F77D6132EABE', // germany
        at: 'X7FAFE69C3AB2D02A20EDB665D751B307', // austria
        ch: 'X5E3655AC1E4FAA1E5F846394C0148531', // switzerland
    } as Record<string, string>;

    const allowedLanguages = ['de'];

    const trustedShopId = computed(() => {
        if (!allowedLanguages.includes(iso2Lang.value) || !localeSuffix) return;
        return allowedCountries[localeSuffix.toLowerCase()];
    });

    onMounted(() => {
        if (trustedShopId.value) {
            try {
                (function () {
                    const _tsid = trustedShopId.value;
                    window._tsConfig = {
                        yOffset: '' /* offset from page bottom */,
                        variant: 'custom_reviews' /* default, reviews, custom, custom_reviews */,
                        customElementId: 'trustedShopBadgeWoom' /* required for variants custom and custom_reviews */,
                        trustcardDirection: '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
                        customBadgeWidth: '' /* for custom variants: 40 - 90 (in pixels) */,
                        customBadgeHeight: '' /* for custom variants: 40 - 90 (in pixels) */,
                        disableResponsive: 'true' /* deactivate responsive behaviour */,
                        disableTrustbadge: 'false' /* deactivate trustbadge */,
                        responsive: { variant: '' },
                    };
                    useHead({
                        script: [
                            {
                                vmid: 'TrustedShop',
                                src: `//widgets.trustedshops.com/js/${_tsid}.js`,
                                async: true,
                                type: 'text/javascript',
                            },
                        ],
                    });
                })();
            } catch (err) {
                console.log('error initializing trustedshops', err);
            }
        }
    });
</script>
<template>
    <div
        v-if="trustedShopId"
        id="trustedShopBadgeWoom"
        :key="trustedShopId"
        class="atm-trusted-shops"></div>
</template>
